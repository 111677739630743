import dayjs from 'dayjs';

export default function () {
  const exportDocHelper = (
    blobDocument,
    MIMEtype = 'application/vnd.ms-excel',
    fileName = '',
    extension = 'xls'
  ) => {
    const blob = new Blob([blobDocument], {
      type: MIMEtype,
    });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute(
      'download',
      `${fileName ? fileName : dayjs().format('DD/MM/YYYY')}.${extension}`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  return { exportDocHelper };
}
