import React, { useState } from 'react';
import { DataGridPro, useGridApiContext } from '@mui/x-data-grid-pro';
import { Pagination, PaginationItem } from '@mui/material';
import Select from 'react-select';
import NoRowsOverlay from '@helpers/NoRowsOverlay';

const DataGridCustom = ({
  columns,
  rows,
  metadata,
  getData,
  handleRowClick,
  pagination = true,
  paginationMode = 'server',
  checkboxSelection = false,
  selectionModel,
  onPageChange,
  onSelectionModelChange,
  onSortModelChange,
  setMetaData,
}) => {
  // Custom pagination including page numbers and dropdown
  const CustomPagination = () => {
    if (!pagination) {
      return null;
    } else {
      const apiRef = useGridApiContext();

      return (
        <div className='flex items-center justify-center w-full mt-4 gap-x-10'>
          <Pagination
            color='error'
            shape='rounded'
            showFirstButton
            showLastButton
            page={metadata?.current_page}
            count={metadata?.last_page}
            renderItem={(props2) => (
              <PaginationItem {...props2} disableRipple />
            )}
            onChange={(event, value) => {
              apiRef.current.setPage(value - 1);
              getData(value, []);
            }}
          />
          <Select
            options={metadata?.options}
            value={metadata?.options?.find(
              (option) => option.value === metadata.current_page
            )}
            onChange={(e) => {
              apiRef.current.setPage(e.value - 1);
              getData(e.value);
              setMetaData((prevState) => ({
                ...prevState,
                current_page: e.value,
              }));
            }}
          />
        </div>
      );
    }
  };

  return (
    <div className='h-[675px]'>
      <DataGridPro
        paginationMode={paginationMode}
        columns={columns}
        rows={rows}
        onSortModelChange={onSortModelChange}
        sortingMode='server'
        sortingOrder={['asc', 'desc']}
        pagination={pagination}
        checkboxSelection={checkboxSelection}
        pageSize={metadata?.last_page}
        disableSelectionOnClick={true}
        rowCount={metadata?.total ?? 0}
        onPageChange={onPageChange}
        onRowClick={handleRowClick}
        disableColumnMenu={true}
        selectionModel={selectionModel}
        keepNonExistentRowsSelected
        onSelectionModelChange={onSelectionModelChange}
        components={{
          NoRowsOverlay: NoRowsOverlay,
          NoResultsOverlay: NoRowsOverlay,
          Pagination: CustomPagination,
        }}
        getRowClassName={(params) =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? ''
            : 'bg-lightgrey-50 rounded-lg'
        }
        sx={{
          border: 'none',
          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '.MuiDataGrid-cell': {
            border: 'none',
          },
          '.MuiDataGrid-cell:focus-within, .MuiDataGrid-cell:focus': {
            outline: 'none !important',
          },
          '.MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-columnHeader:focus':
            {
              outline: 'none !important',
            },
          '.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none !important',
          },
          '.columnHeaderTitleContainer': {
            border: 'none',
          },
          '.MuiDataGrid-columnHeaderCheckbox': {
            borderTopLeftRadius: '0.5rem',
            borderBottomLeftRadius: '0.5rem',
            paddingLeft: '0.25rem',
          },
          '.MuiDataGrid-columnHeaders': {
            border: 'none',
            background: '#5666d325',
            borderRadius: '0.5rem',
          },
          '.MuiDataGrid-row:hover': {
            borderRadius: '0.5rem',
            cursor: 'pointer',
          },
          '.MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '.MuiDataGrid-selectedRowCount': {
            display: 'none',
          },
          '.MuiDataGrid-rowCount': {
            display: 'none',
          },
        }}
      />
    </div>
  );
};

export default DataGridCustom;
