// ** Default import

import React, { useEffect, useState } from 'react';

// ** Utils

import { useTranslation } from 'react-i18next';
import congesService from '@data/congesService';
import SwalCustom from '@helpers/SwalCustom';
import dayjs from 'dayjs';

// ** FontAwesomeIcon

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-regular-svg-icons';
import { faFileArrowDown } from '@fortawesome/pro-regular-svg-icons';

// ** UI

import ToastCustom from '@components/UI/ToastCustom';
import Spinner from '@components/UI/Spinner';
import DataGridCustom from '@components/UI/DataGridCustom';
import { getFullname, getMimeType } from '@utility/Utils';

import useJsHelpers from '@hooks/useJsHelpers';

// ** Exports history

function ExportsHistory() {
  const { t } = useTranslation();
  const [exportHistory, setExportHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { exportDocHelper } = useJsHelpers();

  // Fetching export data
  const fetchExports = async () => {
    try {
      const resp = await congesService.get(`/v1/export-histories`);
      setExportHistory(resp.data.data);
    } catch (err) {
      console.error(err);
      ToastCustom.error(t(err.response?.data?.details));
    }
  };
  // Delete an export
  const handleDeleteExport = async (id) => {
    SwalCustom.delete(
      async () => {
        try {
          await congesService.delete(`/v1/export-histories/${id}`);
          const newExports = (prevState) => {
            return [...prevState].filter((row) => row.id !== id);
          };
          setExportHistory(newExports);
          ToastCustom.validated(t('successDelete'));
        } catch (err) {
          console.error(err);
          ToastCustom.error(t(err.response?.data?.details));
        }
      },
      {
        title: t('areYouSureContinue'),
        text: t('deleteAreForever'),
      }
    );
  };

  // Download an export
  const handleDownloadExport = ({ row }) => {
    congesService
      .get(`/v1/export-histories/${row.id}`, {
        responseType: 'blob',
      })
      .then((res) => {
        // Convert response to xls download
        exportDocHelper(
          res.data,
          getMimeType(row.extension),
          row.file_name,
          row.extension
        );
      })
      .catch((err) => {
        console.error(err);
        ToastCustom.error(t(err.response?.data?.details));
      });
  };

  const initData = async () => {
    await fetchExports();
  };
  useEffect(() => {
    initData().then(() => setIsLoading(false));
  }, []);

  // datagrid columns
  const columns = [
    {
      field: 'creation',
      headerName: t('creation'),
      width: 150,
      flex: 1,
      valueGetter: ({ row }) => dayjs(row.created_at).format('DD/MM/YYYY'),
    },
    {
      field: 'user',
      headerName: t('collaborator'),
      width: 150,
      flex: 1,
      renderCell: ({ row }) => getFullname(row.user),
    },
    {
      field: 'file_name',
      headerName: t('exportName'),
      width: 150,
      flex: 1,
    },
    {
      field: 'actions',
      headerName: t('actions'),
      width: 100,
      renderCell: (row) => {
        return (
          <div className='flex gap-x-4 items-center w-full'>
            <FontAwesomeIcon
              className='cursor-pointer w-5 h-5 text-lightgrey-700'
              icon={faTrash}
              onClick={() => handleDeleteExport(row.id)}
            />

            <FontAwesomeIcon
              className='cursor-pointer w-5 h-5 text-lightgrey-700'
              icon={faFileArrowDown}
              onClick={() => handleDownloadExport(row)}
            />
          </div>
        );
      },
    },
  ];

  return isLoading ? (
    <Spinner />
  ) : (
    <>
      <div className='flex justify-between items-center gap-x-2 mb-8'>
        <span className='bg-lightgrey-50 rounded-lg pt-2.5 pb-2.5 pl-2.5 pr-4'>
          <FontAwesomeIcon
            className='w-4 h-4 pr-2'
            color='#586C9D'
            icon={faCircleInfo}
          />
          {t('storageLimit')}
        </span>
      </div>
      <DataGridCustom
        columns={columns}
        rows={exportHistory}
        pagination={false}
        hideFooter={true}
      />
    </>
  );
}

export default ExportsHistory;
